import { PaletteColorOptions, SimplePaletteColorOptions } from '@mui/material';
import defaultTheme from './default';

declare module '@mui/system/createTheme/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface CommonColors {
    grey: SimplePaletteColorOptions;
    darkGrey: SimplePaletteColorOptions;
    green: SimplePaletteColorOptions;
    red: SimplePaletteColorOptions;
    orange: SimplePaletteColorOptions;
    purple: SimplePaletteColorOptions;
  }

  interface TypeBackground {
    light: string | undefined;
  }

  interface TypeText {
    hint: string | undefined;
  }
}
const themes = {
  default: defaultTheme,
};

export default themes;
