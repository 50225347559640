import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

interface IAppFooter {}

export const AppFooter: React.FC<IAppFooter> = () => {
  let classes = useStyles();
  const { t } = useTranslation(['common']);

  return (
    <Grid component={'footer'} className={classes.footer}>
      <Typography className={classes.copyright} variant="caption">
        {t('juliaRachlin', { ns: 'common' })}
      </Typography>
      <Typography className={classes.copyright} variant="caption">
        {t('independentSeller', { ns: 'common' })}
      </Typography>
    </Grid>
  );
};
