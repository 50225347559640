import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  pageGrid: {
    backgroundColor: '#F6F7F7',
    marginTop: '60px',
    paddingBottom: '60px',
    minHeight: '100vh',
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('mobile')]: {
      padding: '0 16px',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 120px)',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '0 30px',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 120px)',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '50px 150px',
      flexDirection: 'row-reverse',
      height: 'fit-content',
    },
  },
  headerImage: {
    objectFit: 'contain',
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
      flexGrow: '6',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
      flexGrow: '6',
    },
    [theme.breakpoints.up('desktop')]: {
      width: 'calc(50% - 10px)',
      flexGrow: 'unset',
    },
  },
  headerTitleGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('mobile')]: {
      flexGrow: '4',
    },
    [theme.breakpoints.up('tablet')]: {
      flexGrow: '4',
    },
    [theme.breakpoints.up('desktop')]: {
      flexGrow: 'unset',
      height: '100%',
      width: 'calc(50% - 10px)',
      marginInlineEnd: '50px',
    },
  },
  headerTitle: {
    fontWeight: '900',
    textAlign: 'center',
    color: theme.palette.common.black,
    [theme.breakpoints.up('mobile')]: {
      marginBottom: '10px',
    },
    [theme.breakpoints.up('tablet')]: {
      marginBottom: '10px',
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '50px',
    },
  },
  headerSummary: {
    textAlign: 'center',
    [theme.breakpoints.up('mobile')]: {
      display: 'block',
      marginBottom: '0',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'block',
      marginBottom: '0',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'block',
      marginBottom: '25px',
    },
  },
  headerOrderNowBtn: {
    height: '45px',
    borderRadius: '15px',
    [theme.breakpoints.up('mobile')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'block',
    },
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '80px',
    gap: '25px',
    [theme.breakpoints.up('mobile')]: {
      padding: '0 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '0 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '0 150px',
    },
  },
  pageNav: {
    width: '50%',
    [theme.breakpoints.up('mobile')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
    },
  },
  pageNavCard: {
    position: 'sticky',
    height: 'fit-content',
    width: '100%',
    top: 'calc(80px)',
    marginInlineEnd: '50px',
  },
  pageNavItem: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '60px',
    width: '100%',
    borderInlineStart: `3px solid ${theme.palette.common.grey.main}`,
    paddingInlineStart: '20px',
    transition: 'border-color 0.5s, background-color 0.5s',
  },
  pageNavItemSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.grey.main,
  },
  pageContent: {
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },
  stickyFooter: {
    padding: '0 16px',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    right: '0px',
    backgroundColor: '#F6F7F7',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    transition:
      'box-shadow 0.5s, background-color 0.5s, bottom 0.3s, right 0.3s, left 0.3s',
    borderRadius: '22px',
    [theme.breakpoints.up('mobile')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'none',
    },
  },
  stickyFooterElevated: {
    bottom: '8px',
    left: '8px',
    right: '8px',
    backgroundColor: theme.palette.common.white,
    boxShadow:
      'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
  },
  orderNowBtn: {
    height: '45px',
    borderRadius: '15px',
    flex: 'auto',
  },
  backToProductsBtn: {
    borderRadius: '15px',
    height: '45px',
    width: '45px',
  },
}));
