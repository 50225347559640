import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { ContactSection } from './sections/contact/contactSection';
import { ReviewsSection } from './sections/reviews/reviewsSection';
import { WelcomeSection } from './sections/welcome/welcomeSection';
import { CertificatesSection } from './sections/certificates/certificatesSection';
import { AboutSection } from './sections/about/aboutSection';
import { ArticlesSection } from './sections/articles/articlesSection';
import { ProductsSction } from './sections/products/productsSection';
import About1 from '../../assets/about1.png';
import About2 from '../../assets/about2.png';

import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { SEO } from '../../components/seo/seo';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/redux';
import { getArticles } from '../../../core/state/reducers/articles.reducers';
import { IArticle } from '../../../core/state/models';
import { IAData } from '../../../core/state/models/article';

interface IHomePage {}

export const HomePage: React.FC<IHomePage> = () => {
  const classes = useStyles();

  const articles = useAppSelector((state) => state.article.articles);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(['common']);
  const [impArticlesIds, setImpArticlesIds] = useState<string[]>([]);
  const [impArticles, setImpArticles] = useState<IArticle[]>([]);
  const [localedImpArticles, setLocaledImpArticles] = useState<IAData[]>([]);

  useEffect(() => {
    if (articles.length === 0) {
      dispatch(getArticles());
    }
  }, [dispatch, articles]);

  useEffect(() => {
    let tempImpArticles = [];
    for (const article of articles) {
      if (article.type === 'important') {
        tempImpArticles.push(article);
      }
    }
    setImpArticles(tempImpArticles);
  }, [articles]);

  useEffect(() => {
    let tempLocaledImpArticles: IAData[] = [];
    let tempLocaledImpArticleIds: string[] = [];
    for (const impArticle of impArticles) {
      let localedImpArticle: IAData;
      if (impArticle !== null) {
        switch (i18n.language) {
          case 'he': {
            if (impArticle.data.he === undefined) {
              localedImpArticle = impArticle.data.en;
            } else {
              localedImpArticle = impArticle.data.he;
            }
            break;
          }
          case 'rus': {
            if (impArticle.data.rus === undefined) {
              localedImpArticle = impArticle.data.en;
            } else {
              localedImpArticle = impArticle.data.rus;
            }
            break;
          }
          case 'en':
          default:
            localedImpArticle = impArticle.data.en;
        }

        tempLocaledImpArticleIds.push(impArticle.id);
        tempLocaledImpArticles.push(localedImpArticle);
      }
      setLocaledImpArticles(tempLocaledImpArticles);
      setImpArticlesIds(tempLocaledImpArticleIds);
    }
  }, [impArticles, i18n]);

  return (
    <>
      <SEO
        title={t('seoHomeTitle', { ns: 'common' })}
        description={t('seoHomeDescription', { ns: 'common' })}
      />
      <Grid className={classes.pageGrid}>
        <WelcomeSection />
        <CertificatesSection />
        {localedImpArticles.length > 0 && (
          <AboutSection
            image={About1}
            title={localedImpArticles[0].title}
            body={localedImpArticles[0].summary}
            url={`/article?id=${impArticlesIds[0]}`}
          />
        )}
        <ArticlesSection />
        {localedImpArticles.length > 1 && (
          <AboutSection
            image={About2}
            title={localedImpArticles[1].title}
            body={localedImpArticles[1].summary}
            url={`/article?id=${impArticlesIds[1]}`}
          />
        )}
        <ProductsSction />
        <ReviewsSection />
        <ContactSection />
      </Grid>
    </>
  );
};
