import useStyles from './styles';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

interface IAnimatedCard {
  index: number;
  icon: string;
  title: string;
}

export const AnimatedCard: React.FC<IAnimatedCard> = ({
  index,
  icon,
  title,
}) => {
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const cardVariants = {
    visible: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.5, easeOut: [0.22, 1, 0.36, 1] },
    },
    hidden: {
      scale: 0,
      opacity: 0,
    },
  };
  return (
    <motion.div
      key={index}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={cardVariants}
      className={classes.card}
    >
      <img className={classes.icon} src={icon} alt={title} />
      <Typography className={classes.title} variant="subtitle2">
        {title}
      </Typography>
    </motion.div>
  );
};
