import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/redux';
import { IArticle } from '../../../core/state/models';
import { getArticles } from '../../../core/state/reducers/articles.reducers';
import { ArticleSection, FirebaseImage } from '../../components';
import { SEO } from '../../components/seo/seo';
import useStyles from './styles';

interface IArticlePage {}

export const ArticlePage: React.FC<IArticlePage> = () => {
  const articles = useAppSelector((state) => state.article.articles);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [article, setArticle] = useState<IArticle | null>(null);
  const { t, i18n } = useTranslation(['common']);
  let localedArticle = null;

  useEffect(() => {
    if (articles.length === 0) {
      dispatch(getArticles());
    }
    const articled = searchParams.get('id');
    const a = articles.find((a) => a.id === articled);
    if (a !== undefined) {
      setArticle(a);
    }
  }, [articles, searchParams, dispatch]);

  if (article !== null) {
    switch (i18n.language) {
      case 'he': {
        localedArticle = article.data.he;
        if (localedArticle === undefined) {
          localedArticle = article.data.en;
        }
        break;
      }
      case 'rus': {
        localedArticle = article.data.rus;
        if (localedArticle === undefined) {
          localedArticle = article.data.en;
        }
        break;
      }
      case 'en':
      default:
        localedArticle = article.data.en;
    }
  }

  return (
    <>
      <SEO
        title={`${localedArticle?.title ?? ''} ${t('seoArticleTitle', {
          ns: 'common',
        })}`}
        description={localedArticle?.summary ?? ''}
        image={localedArticle?.image ?? ''}
      />
      <Grid className={classes.pageGrid}>
        {localedArticle !== null && (
          <>
            <Grid className={classes.pageHeader}>
              <Divider className={classes.divider} />

              <Typography className={classes.headerTitle} variant="h4">
                {localedArticle.title}
              </Typography>
              <Typography className={classes.headerSummary} variant="body2">
                {localedArticle.summary}
              </Typography>

              <FirebaseImage
                className={classes.headerImage}
                src={localedArticle.image}
              ></FirebaseImage>
            </Grid>
            <Grid className={classes.pageBody}>
              {localedArticle.sections.map((section, index) => (
                <ArticleSection section={section} key={index} />
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
