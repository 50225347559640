import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainGrid: {
    width: 'inherit',
    display: 'flex',
    padding: '40px 20px',
    position: 'relative',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('mobile')]: {
      padding: '40px 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '40px 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '40px 150px',
    },
  },
  title: {
    marginBottom: '50px',
    borderBottom: '3px solid #5cdb95',
    width: 'fit-content',
    alignSelf: 'center',
    paddingBottom: '5px',
  },
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
  },
  cardMedia: {
    padding: '10px',
  },
}));
