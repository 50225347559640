import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  menu: {
    width: '65%',
  },
  menuList: {
    padding: 0,
  },
  closeGrid: {
    padding: '0 12px',
    height: '60px',
    alignItems: 'center',
    display: 'flex',
  },
  optionItem: {
    transition: 'left 0.3s',
    left: '-160px',
  },
  optionItemAnimate: {
    left: '0px',
  },
  optionBtn: {
    padding: '8px 20px',
    height: '60px',
  },
  optionBtnSelected: {
    backgroundColor: '#E0E0E0',
  },
  optionText: {
    paddingInlineStart: '7px',
    fontWeight: '500',
    fontSize: '18px',
  },
  optionTextSelected: {
    borderInlineStart: `3px solid ${theme.palette.primary.main}`,
  },
  optionDropDownIcon: {
    color: theme.palette.primary.main,
    justifyContent: 'end',
  },
}));
