import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import useStyles from './styles';
import Quote from '../../../../assets/quote.svg';
import { useTranslation } from 'react-i18next';
import { CardSlider, FirebaseImage } from '../../../../components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/hooks/redux';
import { getReviews } from '../../../../../core/state/reducers/reviews.reducers';
import { IRData, IReview } from '../../../../../core/state/models';

interface IReviewsSection {}

export const ReviewsSection: React.FC<IReviewsSection> = () => {
  const reviews = useAppSelector((state) => state.review.reviews);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(['common']);

  const classes = useStyles();

  useEffect(() => {
    dispatch(getReviews());
  }, [dispatch]);

  return (
    <Grid id="reviews" className={classes.mainGrid}>
      {reviews.length > 0 && (
        <CardSlider title={t('reviews', { ns: 'common' }).toUpperCase()}>
          {reviews.map((review, index) => {
            let localedReview: IRData;
            switch (i18n.language) {
              case 'he': {
                if (review.data.he === undefined) {
                  localedReview = review.data.en;
                } else {
                  localedReview = review.data.he;
                }
                break;
              }
              case 'rus': {
                if (review.data.rus === undefined) {
                  localedReview = review.data.en;
                } else {
                  localedReview = review.data.rus;
                }
                break;
              }
              case 'en':
              default:
                localedReview = review.data.en;
            }
            return (
              <Card key={index} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid container className={classes.cardContentGrid}>
                    <img src={Quote} alt="quote" className={classes.cardIcon} />
                    <Typography className={classes.cardBody} variant="body1">
                      {localedReview.body}
                    </Typography>
                  </Grid>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <FirebaseImage
                    className={classes.cardAvatar}
                    src={localedReview.author.image}
                    alt="avatar"
                  />
                  <Typography className={classes.cardName} variant="body1">
                    {localedReview.author.name}
                  </Typography>
                </CardActions>
              </Card>
            );
          })}
        </CardSlider>
      )}
    </Grid>
  );
};
