import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  pageGrid: {
    overflowX: 'hidden',
    backgroundColor: '#F6F7F7',
    marginTop: '60px',
    paddingBottom: '60px',
    minHeight: '100vh',
  },
  pageHeader: {
    textAlign: 'center',
    position: 'relative',
    height: '50vh',
    backgroundColor: '#F97741',
  },
  headerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 1s',
  },
  pageBody: {
    position: 'relative',
    marginBottom: '20px',
    [theme.breakpoints.up('mobile')]: {
      padding: '50px 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '50px 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '50px 150px',
    },
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  title: {
    textAlign: 'start',
    borderBottom: '3px solid #5cdb95',
    width: 'fit-content',
    alignSelf: 'center',
    paddingBottom: '5px',
  },
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
  },
  cardMedia: {
    objectFit: 'cover',
  },
}));
