import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import React, { Fragment } from 'react';
import useStyles from './styles';
import parse from 'html-react-parser';
import { FirebaseImage } from '../firebaseImage/firebaseImage';
import { Done as DoneIcon } from '@mui/icons-material';
import { faArrowDown as DownloadIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InView } from 'react-intersection-observer';
import {
  IPSection,
  IPSectionItem,
  PBodyValue,
  PSectionType,
  PSubtitleValue,
  PSubtitleImgValue,
  PImgValue,
  PBulletListValue,
  PBulletImgListValue,
  PLinkListValue,
} from '../../../core/state/models';

interface IProductSection {
  section: IPSection;
  onChange: (inView: boolean, entry: IntersectionObserverEntry) => void;
}

export const ProductSection = React.forwardRef<HTMLDivElement, IProductSection>(
  ({ section, onChange }, ref) => {
    const classes = useStyles();
    const buildItem = (item: IPSectionItem) => {
      let value;
      switch (item.type) {
        case PSectionType.SUBTITLE:
          value = item.value as PSubtitleValue;
          return (
            <Typography className={classes.subtitle} variant="h6">
              {value}
            </Typography>
          );
        case 'subtitleImg':
          value = item.value as PSubtitleImgValue;
          return (
            <Grid className={classes.subtitleGrid}>
              <FirebaseImage
                className={classes.subtitleImg}
                src={value.image}
                alt=""
              />
              <Typography className={classes.subtitleText} variant="h6">
                {value.text}
              </Typography>
            </Grid>
          );
        case 'body':
          value = item.value as PBodyValue;
          return (
            <Typography className={classes.body} variant="body1">
              {parse(value)}
            </Typography>
          );
        case 'image':
          value = item.value as PImgValue;
          return <FirebaseImage className={classes.image} src={value} />;
        case 'bulletList':
          value = item.value as PBulletListValue;
          return (
            <List className={classes.bulletList}>
              {value.map((listItem, index) => (
                <ListItem className={classes.bulletListItem} key={index}>
                  <ListItemIcon className={classes.bulletListItemIcon}>
                    <DoneIcon />
                  </ListItemIcon>
                  <Typography
                    className={classes.bulletListItemBody}
                    variant="subtitle1"
                  >
                    {listItem}
                  </Typography>
                </ListItem>
              ))}
            </List>
          );
        case 'bulletImgList':
          value = item.value as PBulletImgListValue;
          return (
            <List className={classes.bulletImgList}>
              {value.map((listItem, index) => (
                <ListItem className={classes.bulletImgListItem} key={index}>
                  <FirebaseImage
                    className={classes.bulletImgListItemImg}
                    src={listItem.image}
                  />
                  <Typography
                    className={classes.bulletImgListItemBody}
                    variant="body2"
                  >
                    <span>{listItem.title}</span> {listItem.body}
                  </Typography>
                </ListItem>
              ))}
            </List>
          );
        case 'linkList':
          value = item.value as PLinkListValue;
          return (
            <List className={classes.linkList}>
              {value.map((listItem, index) => (
                <ListItem key={index} className={classes.linkListItem}>
                  <Button
                    className={classes.linkListItemBtn}
                    onClick={() =>
                      window.open(listItem.link, 'blank', 'noreferrer')
                    }
                  >
                    <Typography
                      className={classes.linkListItemSubtitle}
                      variant="subtitle2"
                    >
                      {listItem.subtitle}
                    </Typography>
                    <FontAwesomeIcon
                      className={classes.linkListItemIcon}
                      icon={DownloadIcon}
                    />
                  </Button>
                </ListItem>
              ))}
            </List>
          );
        default:
          break;
      }
    };

    return (
      <InView as="div" onChange={onChange} rootMargin={'-60px 0px 0px 0px'}>
        <Grid ref={ref} className={classes.section}>
          {section.title && (
            <Typography className={classes.title} variant="h5">
              {section.title}
            </Typography>
          )}
          {section.items.map((item, index) => {
            return <Fragment key={index}>{buildItem(item)}</Fragment>;
          })}
        </Grid>
      </InView>
    );
  },
);
