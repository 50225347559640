import {
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { ReactNode, useRef } from 'react';
import useStyles from './styles';
import Slider, { Settings } from 'react-slick';
import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface ICardSlider {
  title: string;
  children: Array<ReactNode>;
}

export const CardSlider: React.FC<ICardSlider> = ({ title, children }) => {
  const classes = useStyles();
  const sliderRef = useRef<Slider | null>(null);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const tablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const { i18n } = useTranslation();

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: desktop ? 3 : tablet ? 2 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: desktop ? '150px' : tablet ? '60px' : '16px',
    swipeToSlide: true,
    swipe: true,
    arrows: false,
  };

  if (children.length < settings.slidesToShow!) {
    settings.slidesToShow = children.length;
  }

  return (
    <div className={classes.cardSlider}>
      <Grid className={classes.headerGrid}>
        <Grid className={classes.invisGrid}></Grid>
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        <Grid className={classes.arrowsGrid}>
          <IconButton
            className={
              i18n.dir() === 'ltr' ? classes.arrowBack : classes.arrowNext
            }
            onClick={() =>
              i18n.dir() === 'ltr'
                ? sliderRef.current!.slickPrev()
                : sliderRef.current!.slickNext()
            }
          >
            {i18n.dir() === 'ltr' ? (
              <ArrowBackRoundedIcon />
            ) : (
              <ArrowForwardRoundedIcon />
            )}
          </IconButton>
          <IconButton
            className={
              i18n.dir() === 'ltr' ? classes.arrowNext : classes.arrowBack
            }
            onClick={() =>
              i18n.dir() === 'ltr'
                ? sliderRef.current!.slickNext()
                : sliderRef.current!.slickPrev()
            }
          >
            {i18n.dir() === 'ltr' ? (
              <ArrowForwardRoundedIcon />
            ) : (
              <ArrowBackRoundedIcon />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <Slider {...settings} ref={sliderRef}>
        {React.Children.map(children, (child) => {
          return child;
        })}
      </Slider>
    </div>
  );
};
