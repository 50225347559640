import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import useStyles from './styles';
import Logo from '../../assets/logo.svg';
import {
  Menu as MenuIcon,
  Language as LanguageIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
} from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DropMenu } from '../dropMenu/dropMenu';

interface IMyAppBar {
  options: Array<{ title: string; link: string }>;
  toggleSideMenu: MouseEventHandler<HTMLButtonElement>;
}

export const MyAppBar: React.FC<IMyAppBar> = ({ options, toggleSideMenu }) => {
  const navigate = useNavigate();
  let classes = useStyles();
  const location = useLocation();
  const { i18n } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [showBack, setShowback] = useState(false);
  const open = Boolean(anchorEl);
  const theme: Theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (location.pathname === '/article' || location.pathname === '/product') {
      setShowback(true);
    } else {
      setShowback(false);
    }
  }, [location.pathname]);

  const changeLang = (lng: string) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Grid className={classes.section1}>
          {showBack && (
            <IconButton
              sx={{ mx: 2 }}
              className={classes.navMenu}
              onClick={() => navigate(-1)}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          )}
          {!desktop && (
            <IconButton
              sx={{ mx: 2 }}
              className={classes.navMenu}
              onClick={toggleSideMenu}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Grid>

        <Grid className={classes.section2}>
          <Link className={classes.logoLink} to="/">
            <img
              className={classes.logo}
              src={Logo}
              alt="Julia Rachlin Logo"
              // component={Link}
              // to="/"
            />
          </Link>
        </Grid>
        <Grid className={classes.section3}>
          {options.map((option, index) => {
            return (
              <Button
                key={index}
                sx={{ mx: 2 }}
                component={Link}
                to={option.link}
                className={classes.navItemBtn}
              >
                {option.title}
              </Button>
            );
          })}
        </Grid>
        <Grid className={classes.section4}>
          <IconButton
            sx={{ mx: 2 }}
            className={classes.navMenu}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <LanguageIcon />
          </IconButton>
        </Grid>
        <DropMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          options={[
            {
              title: 'English',
              countryCode: 'US',
              onClick: () => changeLang('en'),
            },
            {
              title: 'Russian',
              countryCode: 'RU',
              onClick: () => changeLang('rus'),
            },
            // {
            //   title: 'Hebrew',
            //   countryCode: 'IL',
            //   onClick: () => changeLang('he'),
            // },
          ]}
          selectedId={(i18n.options.supportedLngs as string[]).findIndex(
            (lng) => lng === i18n.language,
          )}
        ></DropMenu>
      </Toolbar>
    </AppBar>
  );
};
