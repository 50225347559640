import { makeStyles } from '@mui/styles';
import { darken } from '@mui/material';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  dialog: {
    padding: '30px',
    maxWidth: '400px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0',
  },
  img: {
    height: '100px',
    width: '100px',
    marginBottom: '25px',
  },
  title: {
    marginBottom: '25px',
  },
  body: {
    color: '#757575',
    marginBottom: '25px',
  },
  actionDoneBtn: {
    width: '100%',
    '&:not(:first-of-type)': {
      margin: '0',
    },
  },
  actionCancelBtn: {
    width: '100%',
    backgroundColor: '#DFDFE1',
    color: theme.palette.common.black,
    '&:not(:first-of-type)': {
      margin: '0',
    },
    '&:hover': {
      backgroundColor: darken('#DFDFE1', 0.1),
    },
  },
  dialogAction: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));
