import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ModalProps,
  Typography,
} from '@mui/material';
import React, { MouseEventHandler } from 'react';
import useStyles from './styles';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

interface IInfoDialog {
  open: boolean;
  onClose: ModalProps['onClose'];
  onDoneAction?: MouseEventHandler<HTMLButtonElement>;
  onCancelAction?: MouseEventHandler<HTMLButtonElement>;
  imgSrc: string;
  titleText: string;
  bodyText: string;
  actionDoneText?: string | null;
  actionCancelText?: string | null;
}

export const InfoDialog: React.FC<IInfoDialog> = ({
  open,
  onClose,
  onDoneAction,
  onCancelAction,
  imgSrc,
  titleText,
  bodyText,
  actionDoneText,
  actionCancelText,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common']);

  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      {open && (
        <Grid className={classes.dialog}>
          <DialogContent className={classes.dialogContent}>
            <img className={classes.img} src={imgSrc} alt="some"></img>
            <Typography className={classes.title} variant={'h5'}>
              {titleText.toUpperCase()}
            </Typography>
            <Typography className={classes.body} variant={'body1'}>
              {parse(bodyText)}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            {onDoneAction !== undefined && (
              <Button
                className={classes.actionDoneBtn}
                variant="contained"
                onClick={onDoneAction}
              >
                {!!actionDoneText
                  ? actionDoneText.toUpperCase()
                  : t('ok', { ns: 'common' }).toUpperCase()}
              </Button>
            )}
            {onCancelAction !== undefined && (
              <Button
                className={classes.actionCancelBtn}
                variant="contained"
                onClick={onCancelAction}
              >
                {!!actionCancelText
                  ? actionCancelText.toUpperCase()
                  : t('cancel', { ns: 'common' }).toUpperCase()}
              </Button>
            )}
          </DialogActions>
        </Grid>
      )}
    </Dialog>
  );
};
