import { getDownloadURL, ref } from '@firebase/storage';
import { storage } from './firebaseConfig';

export async function getFileUrl(path: string) {
  try {
    const storageRef = ref(storage, `${path}`);

    const url = await getDownloadURL(storageRef);
    return url;
  } catch (e) {
    console.log(e);
    return null;
  }
}
