import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'inherit',
    textAlign: 'center',
    [theme.breakpoints.up('mobile')]: {
      padding: '40px 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '40px 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '40px 150px',
    },
  },
  title: {
    marginBottom: '50px',
    borderBottom: '3px solid #5cdb95',
    width: 'fit-content',
    alignSelf: 'center',
    paddingBottom: '5px',
  },
  sectionGrid: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('mobile')]: {
      flexDirection: 'column-reverse',
      gap: '25px',
    },
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'column-reverse',
      gap: '25px',
    },
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      gap: '50px',
    },
  },
  formGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    backgroundColor: theme.palette.common.white,
    padding: '45px 30px',
    borderRadius: '10px',
    boxShadow: '0 15px 30px -10px rgb(91 97 97 / 30%)',
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },
  formTitle: {
    textAlign: 'start',
    marginBottom: '25px',
  },
  sendbtn: {
    height: '56px',
    width: '100%',
  },
  contactInfoGrid: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    borderRadius: '22px',
    [theme.breakpoints.up('mobile')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      alignItems: 'start',
    },
  },
  contactBtn: {
    width: '280px',
    flexDirection: 'column',
    gap: '12px',
    borderRadius: '22px',
    padding: '10px 25px',
    marginBottom: '15px',
    transition: 'background-color 0.2s',
    [theme.breakpoints.up('mobile')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      alignItems: 'start',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& $contactTitleIcon': {
        color: theme.palette.common.white,
      },
      '& $contactTitleText': {
        color: theme.palette.common.white,
      },
      '& $contactText': {
        color: theme.palette.common.white,
      },
    },
  },
  contactTitleGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    transition: 'color 0.2s',
  },
  contactTitleIcon: {
    height: '28px',
    width: '28px',
    color: theme.palette.primary.main,
    transition: 'color 0.2s',
  },
  contactTitleText: {
    color: theme.palette.common.black,
    transition: 'color 0.2s',
  },
  contactText: {
    color: theme.palette.common.black,
    transition: 'color 0.2s',
  },
}));
