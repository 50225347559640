export enum PSectionType {
  SUBTITLE = 'subtitle',
  BODY = 'body',
  SUBTITLEIMG = 'subtitleImg',
  IMAGE = 'image',
  BULLET_LIST = 'bulletList',
  BULLET_IMG_LIST = 'bulletImgList',
  LINKED_LIST = 'linkList',
}

export type PSubtitleValue = string;

export type PSubtitleImgValue = {
  image: string;
  text: string;
};

export type PBodyValue = string;

export type PImgValue = string;

export type PBulletListValue = string[];

export type PBulletImgListValue = [
  {
    image: string;
    title: string;
    body: string;
  },
];

export type PLinkListValue = [
  {
    link: string;
    subtitle: string;
  },
];

export interface IPSectionItem {
  type: PSectionType;
  value:
    | PSubtitleValue
    | PBodyValue
    | PSubtitleImgValue
    | PImgValue
    | PBulletListValue
    | PBulletImgListValue
    | PLinkListValue;
}

export interface IPSection {
  type: PSectionType;
  title: string;
  items: IPSectionItem[];
}

export interface IPData {
  title: string;
  image: string;
  summary: string;
  link: string;
  sections: IPSection[];
}

export interface IProduct {
  id: string;
  order: number;
  data: {
    en: IPData;
    rus?: IPData;
    he?: IPData;
  };
}
