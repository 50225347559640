import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  title: {
    fontWeight: '900',
    marginBottom: '20px',
  },
  subtitle: {
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'center',
  },
  subtitleGrid: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  subtitleImg: {
    width: '30px',
    height: '30px',
  },
  subtitleText: {
    fontWeight: 'bold',
  },
  body: {
    marginBottom: '25px',
    textAlign: 'center',
    lineHeight: '1.8',
  },
  bulletList: {
    padding: 0,
    marginBottom: '25px',
  },
  bulletListItem: {
    marginBottom: '15px',
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
    borderRadius: '15px',
    padding: '5px 5px',
  },
  bulletListItemIcon: {
    color: theme.palette.common.black,
    minWidth: 'unset',
    width: '35px',
    alignSelf: 'start',
    padding: '0 5px',
  },
  bulletListItemBody: {},

  bulletImgList: {
    padding: 0,
    marginBottom: '25px',
  },
  bulletImgListItem: {
    marginBottom: '15px',
    padding: '5px 0',
  },
  bulletImgListItemImg: {
    minWidth: 'unset',
    width: '45px',
    alignSelf: 'start',
    padding: '0',
    paddingInlineEnd: '15px',
  },
  bulletImgListItemBody: {
    '& span': {
      fontWeight: 'bold',
    },
  },

  image: {
    width: '100%',
    marginBottom: '25px',
  },
  linkList: {
    padding: 0,
    marginBottom: '25px',
  },
  linkListItem: {
    padding: '0',
  },
  linkListItemBtn: {
    width: '100%',
    height: '50px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    borderRadius: '15px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    margin: '10px 0',
  },
  linkListItemSubtitle: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
  linkListItemIcon: {
    color: theme.palette.primary.main,
    height: '18px',
  },
}));
