import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { SEO } from '../../components/seo/seo';

interface INotFoundPage {}

export const NotFoundPage: React.FC<INotFoundPage> = () => {
  let classes = useStyles();
  const { t } = useTranslation(['pages', 'common']);

  return (
    <>
      <SEO
        title={t('seoHomeTitle', { ns: 'common' })}
        description={t('seoHomeDescription', { ns: 'common' })}
      />
      <Grid className={classes.pageGrid}>
        <div className={classes.notfound}>
          <div className={classes.notfound404}>
            <h1>404</h1>
          </div>
          <Typography className={classes.title} component="div" variant="h2">
            {t('notFound.title1', { ns: 'pages' })}&nbsp;
            <Box className={classes.nothingSpan} component="span">
              {t('notFound.title2', { ns: 'pages' })}&nbsp;
            </Box>
            {t('notFound.title3', { ns: 'pages' })}...
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            {t('notFound.body1', { ns: 'pages' })}&nbsp;
            <br />
            {t('notFound.body2', { ns: 'pages' })}&nbsp;
            <Link className={classes.contactUsLink} to="/contact">
              {t('notFound.body3', { ns: 'pages' })}&nbsp;
            </Link>
            {t('notFound.body4', { ns: 'pages' })}&nbsp;
          </Typography>
          <Button sx={{ mx: 2 }} component={Link} to="/" variant="contained">
            {t('takeMeHome', { ns: 'common' }).toUpperCase()}
          </Button>
        </div>
      </Grid>
    </>
  );
};
