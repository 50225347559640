import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  CircularProgress,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import Themes from './ui/theme';
import { Router } from './Router';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './core/state/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <Suspense
          fallback={
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          }
        >
          <Router />
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
);
