import { db } from './firebaseConfig';
import { collection, getDocs } from '@firebase/firestore';
import { IArticle, IProduct, IReview } from '../state/models';

enum FirebaseCollection {
  ARTICLES = 'articles',
  PRODUCTS = 'products',
  REVIEWS = 'reviews',
}

export async function getAllArticles() {
  try {
    const querySnapshot = await getDocs(
      collection(db, FirebaseCollection.ARTICLES),
    );
    const articles = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return articles as IArticle[];
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllProducts() {
  try {
    const querySnapshot = await getDocs(
      collection(db, FirebaseCollection.PRODUCTS),
    );
    const products = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return products as IProduct[];
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getAllReviews() {
  try {
    const querySnapshot = await getDocs(
      collection(db, FirebaseCollection.REVIEWS),
    );
    const reviews = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return reviews as IReview[];
  } catch (e) {
    console.log(e);
    return [];
  }
}
