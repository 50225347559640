import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as firestore from '../../firebase/firestore';
import { IReview } from '../models';

const getReviews = createAsyncThunk(
  'article/getReviews',
  async (_, { rejectWithValue }): Promise<Array<IReview>> => {
    return firestore
      .getAllReviews()
      .then((response) => response)
      .catch<any>((err) => rejectWithValue(err));
  },
);

interface IReviewState {
  reviews: IReview[];
}

const initialState: IReviewState = {
  reviews: [],
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  Get Reviews
    builder.addCase(getReviews.pending, (state) => {});
    builder.addCase(getReviews.fulfilled, (state, { payload }) => {
      state.reviews = payload;
    });
    builder.addCase(getReviews.rejected, (state, { error }) => {});
  },
});

//  Actions
export const {} = reviewSlice.actions;
//  Async Actions
export { getReviews };
//  Reducer
export default reviewSlice.reducer;
