import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  pageGrid: {
    overflowX: 'hidden',
    backgroundColor: '#F6F7F7',
    marginTop: '60px',
    paddingBottom: '60px',
    minHeight: '100vh',
  },
  pageHeader: {
    textAlign: 'center',
    position: 'relative',
    height: '50vh',
    backgroundColor: '#FD7942',
  },
  headerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 1s',
  },
  pageHeader2: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: '50px',
    [theme.breakpoints.up('mobile')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      textAlign: 'start',
    },
  },
  titleGridImage: {
    width: '50%',
    height: '100%',
    objectFit: 'contain',
    transition: 'opacity 0.5s',
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
      alignItems: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
      alignItems: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
      alignItems: 'start',
    },
  },
  title: {
    borderBottom: '3px solid #5cdb95',
    width: 'fit-content',
    paddingBottom: '5px',
    marginBottom: '25px',
  },
  body: {},
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
  },
  pageBody: {
    position: 'relative',
    marginBottom: '20px',
    [theme.breakpoints.up('mobile')]: {
      padding: '50px 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '50px 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '50px 150px',
    },
  },
  cardMedia: {
    padding: '10px',
  },
}));
