import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  appBar: {
    position: 'fixed',
    width: '100vw',
    left: '0px',
    top: '0px',
    height: '60px',
    backgroundColor: '#ffffff !important',
    color: '#000000 !important',
    boxShadow: '0 0 30px rgb(0 0 0 / 10%) !important',
    borderBottom: '1px solid rgba(32, 33, 37, 0.12)',
    boxSizing: 'content-box',
  },
  toolBar: {
    [theme.breakpoints.up('mobile')]: {
      margin: '0 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      margin: '0 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      margin: '0 150px',
    },
    padding: '0 !important',
  },
  logo: {
    // width: '60px',
    height: '40px',
  },
  logoLink: {
    // width: '60px',
    height: '40px',
  },
  section1: {
    flex: 1,
    justifyContent: 'flex-start',
    [theme.breakpoints.up('mobile')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'none',
    },
  },
  section2: {
    display: 'flex',
    [theme.breakpoints.up('mobile')]: {
      justifyContent: 'center',
      flex: 2,
    },
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'center',
      flex: 2,
    },
    [theme.breakpoints.up('desktop')]: {
      justifyContent: 'flex-start',
      flex: 1,
    },
  },
  section3: {
    flex: 2,
    justifyContent: 'center',
    flexBasis: 'max-content',
    [theme.breakpoints.up('mobile')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
    },
  },
  section4: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  hide: {
    display: 'none',
  },
  headerIcon: {
    fontSize: 24,
    color: 'rgba(0, 0, 0, 255)',
  },
  navMenu: {},
  navItemBtn: {
    minWidth: 'unset',
    borderRadius: '0',
    borderBottom: `3px solid transparent`,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: alpha(theme.palette.common.black, 0.5),
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      borderRadius: '0',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  navItemBtnSelected: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '0',
  },
}));
