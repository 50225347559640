import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'inherit',
    padding: '40px 0',
    textAlign: 'center',
  },
  title: {
    marginBottom: '25px',
  },
  cardMedia: {
    height: '225px',
  },
}));
