import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    alignItems: 'center',
    [theme.breakpoints.up('mobile')]: {
      padding: '0 10px',
      width: 'calc(100% / 3 - 20px / 3)',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '0 10px',
      width: 'calc(100% / 3 - 20px / 3)',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '0 10px',
      width: 'calc(100% / 6 - 50px / 6)',
    },
  },
  icon: {
    height: '60px',
    width: '60px',
    marginBottom: '10px',
  },
  title: {
    marginBottom: '10px',
    fontWeight: 'bold',
  },
}));
