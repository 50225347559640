import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import React from 'react';
import useStyles from './styles';
import parse from 'html-react-parser';
import { FirebaseImage } from '../firebaseImage/firebaseImage';
import { Done as DoneIcon } from '@mui/icons-material';
import { faArrowDown as DownloadIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ABodyValue,
  ABulletImgListValue,
  ABulletListValue,
  AImgValue,
  ALinkListValue,
  ASectionType,
  ASubtitleValue,
  ATitleValue,
  IASection,
} from '../../../core/state/models';

interface IArticleSection {
  section: IASection;
}

export const ArticleSection: React.FC<IArticleSection> = ({ section }) => {
  const classes = useStyles();

  function buildItem() {
    let value;
    switch (section.type) {
      case ASectionType.TITLE:
        value = section.value as ATitleValue;
        return (
          <Typography className={classes.title} variant="h3">
            {value}
          </Typography>
        );
      case ASectionType.SUBTITLE:
        value = section.value as ASubtitleValue;
        return (
          <Typography className={classes.subtitle} variant="h5">
            {value}
          </Typography>
        );
      case ASectionType.BODY:
        value = section.value as ABodyValue;
        return (
          <Typography className={classes.body} variant="body1">
            {parse(value)}
          </Typography>
        );
      case ASectionType.IMAGE:
        value = section.value as AImgValue;
        return <FirebaseImage className={classes.image} src={value} />;
      case ASectionType.BULLET_LIST:
        value = section.value as ABulletListValue;
        return (
          <List className={classes.bulletList}>
            {value.map((listItem, index) => (
              <ListItem className={classes.bulletListItem} key={index}>
                <ListItemIcon className={classes.bulletListItemIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <Typography
                  className={classes.bulletListItemBody}
                  variant="subtitle1"
                >
                  {listItem}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      case ASectionType.BULLET_IMG_LIST:
        value = section.value as ABulletImgListValue;
        return (
          <List className={classes.bulletImgList}>
            {value.map((listItem, index) => (
              <ListItem className={classes.bulletImgListItem} key={index}>
                <FirebaseImage
                  className={classes.bulletImgListItemImg}
                  src={listItem.image}
                />
                <Typography
                  className={classes.bulletImgListItemBody}
                  variant="body2"
                >
                  <span>{listItem.title}</span> {listItem.body}
                </Typography>
              </ListItem>
            ))}
          </List>
        );
      case ASectionType.LINKED_LIST:
        value = section.value as ALinkListValue;
        return (
          <List className={classes.linkList}>
            {value.map((listItem, index) => (
              <ListItem key={index} className={classes.linkListItem}>
                <Button
                  className={classes.linkListItemBtn}
                  onClick={() => window.open(listItem.link)}
                >
                  <Typography
                    className={classes.linkListItemSubtitle}
                    variant="subtitle2"
                  >
                    {listItem.subtitle}
                  </Typography>

                  <FontAwesomeIcon
                    className={classes.linkListItemIcon}
                    icon={DownloadIcon}
                  />
                </Button>
              </ListItem>
            ))}
          </List>
        );
      default:
        break;
    }
  }

  return <>{buildItem()}</>;
};
