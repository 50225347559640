import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppFooter, MyAppBar, ScrollToTop, SideMenu } from './ui/components';
import { firstLetterToUpperCase } from './core/common/utils/helpers';
import { HelmetProvider } from 'react-helmet-async';

interface IApp {}

export const App: React.FC<IApp> = () => {
  const { t, i18n } = useTranslation(['common']);
  const [openMenu, setOpenMenu] = useState(false);

  const options = [
    { title: firstLetterToUpperCase(t('home', { ns: 'common' })), link: '/' },
    {
      title: firstLetterToUpperCase(t('about', { ns: 'common' })),
      link: '/about',
    },
    {
      title: firstLetterToUpperCase(t('products', { ns: 'common' })),
      link: '/products',
    },
    {
      title: firstLetterToUpperCase(t('articles', { ns: 'common' })),
      link: '/articles',
    },
    {
      title: firstLetterToUpperCase(t('contact', { ns: 'common' })),
      link: '/contact',
    },
  ];
  document.body.dir = i18n.dir();

  const toggleSideMenu = () => {
    setOpenMenu(!openMenu);
  };

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <ScrollToTop />
      <MyAppBar options={options} toggleSideMenu={toggleSideMenu} />
      <SideMenu options={options} open={openMenu} onClose={toggleSideMenu} />
      <main>
        <Outlet />
      </main>
      <AppFooter />
    </HelmetProvider>
  );
};
