import { Button, ButtonBase, Grid, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import {
  FacebookRounded as FacebookIcon,
  WhatsApp as WhatsAppIcon,
  Phone as PhoneIcon,
  Instagram as InstagramIcon,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { CustomImage, InfoDialog } from '../../components';
import DialogContactThanksIcon from '../../assets/dialog_contact_thanks.svg';
import DialogContactErrorIcon from '../../assets/dialog_error.svg';
import { useTranslation } from 'react-i18next';
import { firstLetterToUpperCase } from '../../../core/common/utils/helpers';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArticlesBg from '../../assets/contact_bg.webp';
import { SEO } from '../../components/seo/seo';

interface IContactPage {}

export const ContactPage: React.FC<IContactPage> = () => {
  const classes = useStyles();
  const [fullName, setFullName] = useState('');
  const [message, setMessage] = useState('');
  const [openThanksDialog, setOpenThanksDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const { t } = useTranslation(['pages', 'common']);

  var messageTemp = t('contactMessageTemplate', { ns: 'common' });

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required(
      firstLetterToUpperCase(t('fullNameRequired', { ns: 'common' })),
    ),
    message: Yup.string().required(
      firstLetterToUpperCase(t('messageRequired', { ns: 'common' })),
    ),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function sendMessage() {
    if (fullName.length === 0 || message.length === 0) {
      //setOpenErrorDialog(true);
      return;
    }
    messageTemp = messageTemp
      .replace('[[name]]', fullName)
      .replace('[[message]]', message);

    window.open(
      `https://api.whatsapp.com/send/?phone=972546343774&text=${messageTemp}`,
      '_blank',
      'noreferrer',
    );
    reset(
      {
        fullname: '',
        message: '',
      },
      {
        keepErrors: true,
        keepDirty: true,
      },
    );

    setFullName('');
    setMessage('');
    setOpenThanksDialog(true);
  }

  const handleClose = () => {
    if (openThanksDialog) {
      setOpenThanksDialog(false);
    } else if (openErrorDialog) {
      setOpenErrorDialog(false);
    }
  };

  const contactData = [
    {
      title: t('phoneNumber', { ns: 'common' }).toUpperCase(),
      icon: <PhoneIcon className={classes.contactTitleIcon} />,
      text: '+972 546343774',
      href: 'tel:972546343774',
    },
    {
      title: t('whatsapp', { ns: 'common' }).toUpperCase(),
      icon: <WhatsAppIcon className={classes.contactTitleIcon} />,
      text: '+972 546343774',
      href: 'https://api.whatsapp.com/send/?phone=972546343774',
    },
    {
      title: t('instagram', { ns: 'common' }).toUpperCase(),
      icon: <InstagramIcon className={classes.contactTitleIcon} />,
      text: 'julia_rachlin_',
      href: 'https://www.instagram.com/julia_rachlin_/',
    },
    {
      title: t('facebook', { ns: 'common' }).toUpperCase(),
      icon: <FacebookIcon className={classes.contactTitleIcon} />,
      text: 'Juice Plus+ Израиль',
      href: 'https://www.facebook.com/profile.php?id=100088671577925',
    },
  ];

  return (
    <>
      <SEO
        title={t('seoContactTitle', { ns: 'common' })}
        description={t('seoContactDescription', { ns: 'common' })}
      />
      <Grid className={classes.pageGrid}>
        <Grid className={classes.pageHeader}>
          <CustomImage
            className={classes.headerImage}
            src={ArticlesBg}
            alt="a"
          />
        </Grid>
        <Grid className={classes.pageBody}>
          <Grid className={classes.titleGrid}>
            <Typography className={classes.title} variant="h4">
              {t('contact', { ns: 'common' }).toUpperCase()}
            </Typography>
            <Typography className={classes.subtitle} variant="h5">
              {parse(t('contact.subtitle', { ns: 'pages' }))}
            </Typography>
          </Grid>
          <Grid className={classes.sectionGrid}>
            <Grid className={classes.contactInfoGrid}>
              {contactData.map((data, index) => {
                return (
                  <ButtonBase
                    key={index}
                    className={classes.contactBtn}
                    // variant="contained"
                    target="_blank"
                    href={data.href}
                  >
                    <Grid className={classes.contactTitleGrid}>
                      {data.icon}
                      <Typography
                        className={classes.contactTitleText}
                        variant="subtitle1"
                      >
                        {data.title}
                      </Typography>
                    </Grid>
                    <Typography className={classes.contactText} variant="body2">
                      {data.text}
                    </Typography>
                  </ButtonBase>
                );
              })}
            </Grid>
            <Grid className={classes.formGrid}>
              <Typography className={classes.formTitle} variant="h5">
                {t('sendMessage', { ns: 'common' }).toUpperCase()}
              </Typography>
              <TextField
                label={firstLetterToUpperCase(t('fullName', { ns: 'common' }))}
                variant="filled"
                value={fullName}
                {...register('fullname')}
                required
                error={!!errors['fullname']}
                helperText={
                  errors['fullname'] ? <>errors['fullname'].message</> : ''
                }
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
              />
              <TextField
                label={`${firstLetterToUpperCase(
                  t('message', { ns: 'common' }),
                )}...`}
                variant="filled"
                multiline={true}
                rows={5}
                value={message}
                {...register('message')}
                required
                error={!!errors['message']}
                helperText={
                  errors['message'] ? <>errors['message'].message</> : ''
                }
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <Button
                className={classes.sendbtn}
                variant="contained"
                // target="_blank"
                onClick={handleSubmit(sendMessage)}
              >
                {t('send', { ns: 'common' }).toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InfoDialog
        open={openThanksDialog || openErrorDialog}
        onClose={handleClose}
        onDoneAction={openThanksDialog ? handleClose : undefined}
        onCancelAction={openErrorDialog ? handleClose : undefined}
        imgSrc={
          openThanksDialog ? DialogContactThanksIcon : DialogContactErrorIcon
        }
        titleText={
          openThanksDialog
            ? t('thankYou', { ns: 'common' })
            : t('oops', { ns: 'common' })
        }
        bodyText={
          openThanksDialog
            ? t('thankYouDesc', { ns: 'common' })
            : t('oopsDesc', { ns: 'common' })
        }
        actionDoneText={t('ok', { ns: 'common' })}
      />
    </>
  );
};
