import { CombinedState, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as firestore from '../../firebase/firestore';
import { IProduct } from '../models';
import { RootState } from '../store';

const getProdcuts = createAsyncThunk(
  'prodcut/getProdcuts',
  async (_, { rejectWithValue }): Promise<Array<IProduct>> => {
    return firestore
      .getAllProducts()
      .then((response) => response)
      .catch<any>((err) => rejectWithValue(err));
  },
);

interface IProductState {
  products: IProduct[];
}

const initialState: IProductState = {
  products: [],
};

export const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  Get Prodcuts
    builder.addCase(getProdcuts.pending, (state) => {});
    builder.addCase(getProdcuts.fulfilled, (state, { payload }) => {
      state.products = payload;
    });
    builder.addCase(getProdcuts.rejected, (state, { error }) => {});
  },
});

//  Actions
export const {} = productSlice.actions;
//  Async Actions
export { getProdcuts };
//  Reducer
export default productSlice.reducer;

export const productsSelector = (state: RootState) =>
  [...state.product.products].sort((a, b) => a.order - b.order);
