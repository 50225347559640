import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  pageGrid: {
    overflowX: 'hidden',
    backgroundColor: '#F6F7F7',
    marginTop: '60px',
    paddingBottom: '60px',
    minHeight: '100vh',
  },
  pageHeader: {
    textAlign: 'center',
    position: 'relative',
    height: '50vh',
    backgroundColor: '#F3B11E',
  },
  headerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 1s',
  },
  pageBody: {
    position: 'relative',
    marginBottom: '20px',
    textAlign: 'center',
    maxWidth: '1000px',
    [theme.breakpoints.up('mobile')]: {
      padding: '50px 16px',
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '50px 30px',
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '50px 150px',
      marginInline: 'auto',
    },
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  title: {
    textAlign: 'start',
    borderBottom: '3px solid #5cdb95',
    width: 'fit-content',
    alignSelf: 'center',
    paddingBottom: '5px',
    marginBottom: '25px',
  },
  parTitle: {
    fontWeight: '900',
    marginBottom: '20px',
  },
  parSubtitle: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  parBody: {
    marginBottom: '25px',
    textAlign: 'center',
  },
  bulletList: {
    padding: 0,
    marginBottom: '25px',
  },
  bulletListItem: {
    padding: '0',
    marginBottom: '10px',
  },
  bulletListItemIcon: {
    color: theme.palette.primary.main,
    minWidth: '35px',
    alignSelf: 'start',
  },
  bulletListItemBody: {},
  image: {
    width: '100%',
    marginBottom: '25px',
  },
}));
