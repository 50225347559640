import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  pageGrid: {
    overflowX: 'hidden',
    backgroundColor: '#F6F7F7',
    marginTop: '60px',
    paddingBottom: '60px',
    minHeight: '100vh',
  },
}));
