import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const supportedLngs = ['en', 'rus'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    fallbackLng: 'en',
    debug: true,
    supportedLngs: supportedLngs,
    interpolation: {
      escapeValue: false,
    },
    ns: ['common', 'pages'],
  });

export default i18n;
