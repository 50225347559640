import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  pageGrid: {
    overflowX: 'hidden',
    backgroundColor: '#F6F7F7',
    marginTop: '60px',
    paddingBottom: '60px',
    minHeight: '100vh',
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    marginBottom: '30px',
    textAlign: 'center',
    [theme.breakpoints.up('mobile')]: {
      padding: '50px 16px 0',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '50px 30px 0 ',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '50px 150px 0',
    },
  },
  headerImage: {
    width: '100%',
    height: 'calc(65vh - 60px)',
    objectFit: 'cover',
  },
  headerTitle: {
    fontWeight: '900',
    marginBottom: '15px',
  },
  headerSummary: {
    marginBottom: '25px',
    maxWidth: '500px',
    marginInline: 'auto',
  },
  divider: {
    display: 'flex',
    width: '60px',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    alignSelf: 'center',
    marginBottom: '25px',
  },
  pageBody: {
    maxWidth: '1000px',
    [theme.breakpoints.up('mobile')]: {
      padding: '0 16px',
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '0 30px',
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '0 150px',
      marginInline: 'auto',
    },
  },
}));
