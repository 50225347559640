import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainGrid: {
    position: 'relative',
    width: 'inherit',
    textAlign: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.secondary.main,
    gap: '10px',
    display: 'flex',
    [theme.breakpoints.up('mobile')]: {
      padding: '40px 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '40px 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '40px 150px',
    },
  },
}));
