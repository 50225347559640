import { Grid } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { CardSlider, SliderCard } from '../../../../components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/hooks/redux';
import { getArticles } from '../../../../../core/state/reducers/articles.reducers';

interface IArticlesSection {}

export const ArticlesSection: React.FC<IArticlesSection> = () => {
  const articles = useAppSelector((state) => state.article.articles);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(['common']);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  return (
    <Grid className={classes.mainGrid}>
      <CardSlider title={t('articles', { ns: 'common' }).toUpperCase()}>
        {articles.map((article, index) => {
          let articleData;
          const id = article.id;
          switch (i18n.language) {
            case 'he': {
              articleData = article.data.he;
              if (articleData === undefined) {
                articleData = article.data.en;
              }
              break;
            }
            case 'rus': {
              articleData = article.data.rus;
              if (articleData === undefined) {
                articleData = article.data.en;
              }
              break;
            }
            case 'en':
            default:
              articleData = article.data.en;
          }
          return (
            <Fragment key={index}>
              <SliderCard
                url={`/article?id=${id}`}
                imageSrc={articleData.image}
                imageAlt={'a'}
                titleText={articleData.title}
                summaryText={articleData.summary}
                showBtn={true}
                buttonText={t('readMore', { ns: 'common' }).toUpperCase()}
              />
            </Fragment>
          );
        })}
      </CardSlider>
    </Grid>
  );
};
