import { Button, Grid, IconButton, Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import { Storefront as StorefrontIcon } from '@mui/icons-material';
import classNames from 'classnames';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DialogRedirectIcon from '../../assets/dialog_redirect.svg';
import { FirebaseImage, InfoDialog, ProductSection } from '../../components';
import { SEO } from '../../components/seo/seo';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/redux';
import {
  getProdcuts,
  productsSelector,
} from '../../../core/state/reducers/products.reducers';
import { IProduct } from '../../../core/state/models';
import { IPData } from '../../../core/state/models/product';

type ViewList = {};

interface IProductPage {}

export const ProductPage: React.FC<IProductPage> = () => {
  const products = useAppSelector(productsSelector);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [footerElevate, setFooterElevate] = useState(false);
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState<IProduct | null>(null);
  const { t, i18n } = useTranslation(['common']);
  const [openDialog, setOpenDialog] = useState(false);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  let localedProduct: IPData | null = null;
  const [inViewList, setInViewList] = useState<ViewList>({ '0': true });
  const navigate = useNavigate();

  const executeScroll = (index: number) =>
    itemsRef.current[index]?.scrollIntoView();

  useEffect(() => {
    if (products.length === 0) {
      dispatch(getProdcuts());
      return;
    }
    const productId = searchParams.get('id');
    const p: IProduct | null = products.find((p) => p.id === productId) ?? null;
    setProduct(p);
  }, [products, searchParams, dispatch]);

  useEffect(() => {
    const changeFooter = () => {
      if (window.scrollY > 0 && !footerElevate) {
        setFooterElevate(true);
      } else if (window.scrollY <= 0 && footerElevate) {
        setFooterElevate(false);
      }
    };
    changeFooter();

    window.addEventListener('scroll', changeFooter);

    return () => {
      window.removeEventListener('scroll', changeFooter);
    };
  }, [footerElevate]);

  if (product !== null && product !== undefined) {
    switch (i18n.language) {
      case 'he': {
        if (product.data.he !== undefined) {
          localedProduct = product.data.he;
          break;
        }
        localedProduct = product.data.en;
        break;
      }
      case 'rus': {
        if (product.data.rus !== undefined) {
          localedProduct = product.data.rus;
          break;
        }
        localedProduct = product.data.en;

        break;
      }
      case 'en':
      default:
        localedProduct = product.data.en;
    }
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const redirectToOrder = () => {
    window.open(localedProduct!.link, 'blank');
    setOpenDialog(false);
  };

  if (product === undefined) {
    navigate('/products');
  }

  return (
    <>
      <SEO
        title={`${localedProduct?.title ?? ''} ${t('seoProductTitle', {
          ns: 'common',
        })}`}
        description={localedProduct?.summary ?? ''}
        image={localedProduct?.image ?? ''}
      />
      <Grid className={classes.pageGrid}>
        {localedProduct !== null && (
          <>
            <Grid className={classes.pageHeader}>
              <FirebaseImage
                className={classes.headerImage}
                src={localedProduct.image}
                alt="a"
              />
              <Grid className={classes.headerTitleGrid}>
                <Typography className={classes.headerTitle} variant="h4">
                  {localedProduct.title}
                </Typography>

                <Typography className={classes.headerSummary} variant="body1">
                  {localedProduct.summary}
                </Typography>
                <Button
                  className={classes.headerOrderNowBtn}
                  variant="contained"
                  onClick={() => setOpenDialog(true)}
                >
                  {t('orderNow', { ns: 'common' }).toUpperCase()}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.pageBody}>
              <Grid className={classes.pageNav}>
                <Grid className={classes.pageNavCard}>
                  {localedProduct.sections.map((section, index) => {
                    if (section.title === undefined) {
                      return <Fragment key={index}></Fragment>;
                    }
                    return (
                      <Grid
                        key={index}
                        className={classNames(classes.pageNavItem, {
                          [classes.pageNavItemSelected]:
                            Object.keys(inViewList).filter(
                              (key) => inViewList[key as keyof ViewList],
                            ).length > 0 &&
                            Math.min(
                              ...Object.keys(inViewList)
                                .filter(
                                  (key) => inViewList[key as keyof ViewList],
                                )
                                .map<number>((key) => +key),
                            ) === index,
                        })}
                        onClick={() => executeScroll(index)}
                      >
                        <Typography variant="subtitle1">
                          {section.title}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid className={classes.pageContent}>
                {localedProduct.sections.map((section, index) => (
                  <Fragment key={index}>
                    <ProductSection
                      section={section}
                      ref={(ref: HTMLDivElement) =>
                        (itemsRef.current[index] = ref)
                      }
                      key={index}
                      onChange={(inView) => {
                        setInViewList({ ...inViewList, [index]: inView });
                      }}
                    />
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          className={classNames(classes.stickyFooter, {
            [classes.stickyFooterElevated]: footerElevate,
          })}
        >
          <IconButton
            className={classes.backToProductsBtn}
            // variant="contained"
            component={Link}
            to="/products"
          >
            <StorefrontIcon />
          </IconButton>
          <Button
            className={classes.orderNowBtn}
            variant="contained"
            onClick={() => setOpenDialog(true)}
          >
            {t('orderNow', { ns: 'common' }).toUpperCase()}
          </Button>
        </Grid>
      </Grid>
      <InfoDialog
        open={openDialog}
        onClose={handleClose}
        onDoneAction={redirectToOrder}
        onCancelAction={handleClose}
        imgSrc={DialogRedirectIcon}
        titleText={t('redirecting', { ns: 'common' })}
        bodyText={t('redirectingDesc', { ns: 'common' })}
        actionDoneText={t('letsGo', { ns: 'common' })}
        actionCancelText={t('cancel', { ns: 'common' })}
      />
    </>
  );
};
