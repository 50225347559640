import { Button, Divider, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useStyles from './styles';

interface IAboutSection {
  image: string;
  title: string;
  body: string;
  url: string;
  switchOrder?: boolean;
}

export const AboutSection: React.FC<IAboutSection> = ({
  image,
  title,
  body,
  url,
  switchOrder = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common']);

  return (
    <Grid
      className={classNames(classes.mainGrid, {
        [classes.mainGridInv]: switchOrder,
      })}
    >
      <img className={classes.image} src={image} alt="a" />
      <Grid className={classes.textGrid}>
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.body} variant="body1">
          {body}
        </Typography>
        <Button
          className={classes.btn}
          variant="outlined"
          component={Link}
          to={url}
        >
          {t('readMore', { ns: 'common' }).toUpperCase()}
        </Button>
      </Grid>
    </Grid>
  );
};
