import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  card: {
    display: 'flex !important',
    flexDirection: 'column',
    borderRadius: '10px',
    cursor: 'pointer',
    boxShadow: '0 15px 30px -10px rgb(91 97 97 / 30%)',
    height: '560px',
    transition: 'transform 0.3s',
    overflow: 'none',
    '&:hover': {
      transform: 'scale(1.01)',
      '& $cardTitle': {
        color: theme.palette.primary.main,
      },
      '& $cardMedia': {
        transform: 'scale(1.1)',
      },
    },
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      width: 'calc(50% - 10px)',
    },
    [theme.breakpoints.up('desktop')]: {
      width: 'calc(50% - 10px)',
    },
  },
  cardLast: {
    marginRight: 0,
  },
  cardLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  cardMedia: {
    height: '225px',
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
    transition: 'transform 0.3s',
  },
  cardContent: {
    padding: '30px 30px 0 35px',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: '1',
  },
  cardContentGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    overflow: 'hidden',
    textAlign: 'start',
  },
  cardTitle: {
    marginBottom: '15px',
    transition: 'color 0.3s',
  },
  cardSummary: {
    color: '#414848',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkitLineClamp': '6',
    '-webkitBoxOrient': 'vertical',
  },
  cardActions: {
    justifyContent: 'center',
    padding: '30px 30px 35px',
    alignItems: 'center',
    boxSizing: 'content-box',
  },
  cardBtn: {
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
    width: '100%',
  },
}));
