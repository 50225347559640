import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

interface ISideMenuOption {
  title: string;
  link: string;
}

interface ISideMenu {
  options: Array<ISideMenuOption>;
  open: boolean;
  onClose(): void;
}

export const SideMenu: React.FC<ISideMenu> = ({ options, open, onClose }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(open);
  }, [open]);

  return (
    <Drawer
      variant="persistent"
      anchor={i18n.dir() === 'rtl' ? 'right' : 'left'}
      open={open}
      onClose={(event) => {
        // setAnimate(false);
        onClose();
      }}
      PaperProps={{
        style: { borderStartEndRadius: '25px', width: '65%' },
      }}
    >
      <Box
        className={classes.menu}
        role="presentation"
        onClick={(event) => {
          // setAnimate(false);
          onClose();
        }}
      >
        <List className={classes.menuList}>
          <Grid className={classes.closeGrid}>
            <IconButton
              onClick={() => {
                // setAnimate(false);
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          {options.map((option, index) => (
            <ListItem
              className={classNames(classes.optionItem, {
                [classes.optionItemAnimate]: animate,
              })}
              style={{ transitionDelay: `${0.2 + 0.1 * index}s` }}
              key={option.title}
              disablePadding
            >
              <ListItemButton
                className={classes.optionBtn}
                component={Link}
                to={option.link}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      className={classes.optionText}
                      variant="subtitle1"
                    >
                      {option.title}
                    </Typography>
                  }
                />
                {/* {option.children !== undefined && (
                  <ListItemIcon className={classes.optionDropDownIcon}>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                )} */}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
