import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { getFileUrl } from '../../../core/firebase/storage';

interface IFirebaseImage {
  className: string;
  src: string;
  alt?: string;
}

export const FirebaseImage: React.FC<IFirebaseImage> = ({
  className,
  src,
  alt,
}) => {
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    // let mounted = true;

    getFileUrl(src).then((url) => {
      // if (mounted) {
      setImage(url);
      // }
    });

    // return () => (mounted = false);
  }, [src]);

  return (
    <>
      {image !== null ? (
        <img className={className} src={image} alt={alt}></img>
      ) : (
        <CircularProgress className={className} />
      )}
    </>
  );
};
