import { Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomImage, ItemCard } from '../../components';
import useStyles from './styles';
import ProductsBg from '../../assets/products_bg.webp';
import ProductsBg2 from '../../assets/shop_bg.webp';
import { SEO } from '../../components/seo/seo';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/redux';
import {
  getProdcuts,
  productsSelector,
} from '../../../core/state/reducers/products.reducers';

interface IProductsPage {}

export const ProductsPage: React.FC<IProductsPage> = () => {
  const products = useAppSelector(productsSelector);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(['common']);

  const classes = useStyles();

  useEffect(() => {
    dispatch(getProdcuts());
  }, [dispatch]);

  return (
    <>
      <SEO
        title={t('seoProductsTitle', { ns: 'common' })}
        description={t('seoProductsDescription', { ns: 'common' })}
      />
      <Grid className={classes.pageGrid}>
        <Grid className={classes.pageHeader}>
          <CustomImage
            className={classes.headerImage}
            src={ProductsBg}
            alt="a"
          />
        </Grid>
        <Grid className={classes.pageBody}>
          <Grid className={classes.pageHeader2}>
            <Grid className={classes.titleGrid}>
              <Typography className={classes.title} variant="h4">
                {t('products', { ns: 'common' }).toUpperCase()}
              </Typography>
              <Typography className={classes.body} variant="body1">
                Juice Plus+ products make healthy living easier by helping you
                bridge the gap between what you should eat, and what you do eat,
                every day. Every Juice Plus+ product delivers concentrated
                plant-based nutrition that is whole food based and as close to
                nature as possible.
              </Typography>
            </Grid>
            <CustomImage
              className={classes.titleGridImage}
              src={ProductsBg2}
              alt="a"
            />
          </Grid>
          <Grid className={classes.cardGrid}>
            {products.map((product, index) => {
              let localedProduct;
              const id = product.id;
              switch (i18n.language) {
                case 'he': {
                  localedProduct = product.data.he;
                  if (localedProduct === undefined) {
                    localedProduct = product.data.en;
                  }
                  break;
                }
                case 'rus': {
                  localedProduct = product.data.rus;
                  if (localedProduct === undefined) {
                    localedProduct = product.data.en;
                  }
                  break;
                }
                case 'en':
                default:
                  localedProduct = product.data.en;
              }
              return (
                <Fragment key={index}>
                  <ItemCard
                    id={index}
                    url={`/product?id=${id}`}
                    imageClassName={classes.cardMedia}
                    imageSrc={localedProduct.image}
                    imageAlt={'a'}
                    titleText={localedProduct.title}
                    summaryText={localedProduct.summary}
                    showBtn={true}
                    buttonText={t('orderNow', { ns: 'common' }).toUpperCase()}
                  />
                </Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
