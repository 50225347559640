import { combineReducers } from 'redux';
import articlesReducers from './reducers/articles.reducers';
import productsReducers from './reducers/products.reducers';
import reviewsReducers from './reducers/reviews.reducers';

const rootReducer = combineReducers({
  article: articlesReducers,
  product: productsReducers,
  review: reviewsReducers,
});

export default rootReducer;
