import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FirebaseImage } from '../firebaseImage/firebaseImage';
import useStyles from './styles';

interface IItemCard {
  id: number;
  url: string;
  cardClassName?: string;
  imageClassName?: string;
  imageSrc: string;
  imageAlt?: string;
  titleClassName?: string;
  titleText: string;
  summaryClassName?: string;
  summaryText: string;
  showBtn: boolean;
  buttonClassName?: string;
  buttonText: string;
}

export const ItemCard: React.FC<IItemCard> = ({
  id,
  url,
  cardClassName,
  imageClassName,
  imageSrc,
  imageAlt,
  titleClassName,
  titleText,
  summaryClassName,
  summaryText,
  showBtn,
  buttonClassName,
  buttonText,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Card
      key={id}
      className={classNames(
        classes.card,
        cardClassName && {
          [cardClassName]: cardClassName !== undefined,
        },
      )}
      onClick={() => navigate(url)}
    >
      <FirebaseImage
        className={classNames(
          classes.cardMedia,
          imageClassName && {
            [imageClassName]: imageClassName !== undefined,
          },
        )}
        src={imageSrc}
        alt={imageAlt}
      />
      <CardContent className={classes.cardContent}>
        <Grid container className={classes.cardContentGrid}>
          <Typography
            className={classNames(
              classes.cardTitle,
              titleClassName && {
                [titleClassName]: titleClassName !== undefined,
              },
            )}
            variant="h5"
          >
            {titleText}
          </Typography>
          <Typography
            className={classNames(
              classes.cardSummary,
              summaryClassName && {
                [summaryClassName]: summaryClassName !== undefined,
              },
            )}
            variant="body2"
          >
            {summaryText}
          </Typography>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        {showBtn && (
          <Button
            className={classNames(
              classes.cardBtn,
              buttonClassName && {
                [buttonClassName]: buttonClassName !== undefined,
              },
            )}
            variant="outlined"
            component={Link}
            to={url}
          >
            {buttonText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
