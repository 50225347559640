export enum ASectionType {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  BODY = 'body',
  IMAGE = 'image',
  BULLET_LIST = 'bulletList',
  BULLET_IMG_LIST = 'bulletImgList',
  LINKED_LIST = 'linkList',
}

export type ATitleValue = string;

export type ASubtitleValue = string;

export type ABodyValue = string;

export type ABulletListValue = string[];

export type AImgValue = string;

export type ABulletImgListValue = [
  {
    image: string;
    title: string;
    body: string;
  },
];

export type ALinkListValue = [
  {
    link: string;
    subtitle: string;
  },
];

export interface IASection {
  type: ASectionType;
  value:
    | ATitleValue
    | ASubtitleValue
    | ABodyValue
    | AImgValue
    | ABulletImgListValue
    | ABulletListValue
    | ALinkListValue;
}

export interface IAData {
  title: string;
  image: string;
  summary: string;
  sections: IASection[];
}

export interface IArticle {
  id: string;
  type: string;
  data: {
    en: IAData;
    rus?: IAData;
    he?: IAData;
  };
}
