import { Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { ItemCard } from '../../../../components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/hooks/redux';
import {
  getProdcuts,
  productsSelector,
} from '../../../../../core/state/reducers/products.reducers';

interface IProductsSction {}

export const ProductsSction: React.FC<IProductsSction> = () => {
  const products = useAppSelector(productsSelector);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(['common']);

  const classes = useStyles();

  useEffect(() => {
    dispatch(getProdcuts());
  }, [dispatch]);

  return (
    <Grid className={classes.mainGrid}>
      <Typography className={classes.title} variant="h4">
        {t('products', { ns: 'common' }).toUpperCase()}
      </Typography>
      <Grid className={classes.cardGrid}>
        {products.map((product, index) => {
          let localedProduct;
          const id = product.id;
          switch (i18n.language) {
            case 'he': {
              localedProduct = product.data.he;
              if (localedProduct === undefined) {
                localedProduct = product.data.en;
              }
              break;
            }
            case 'rus': {
              localedProduct = product.data.rus;
              if (localedProduct === undefined) {
                localedProduct = product.data.en;
              }
              break;
            }
            case 'en':
            default:
              localedProduct = product.data.en;
          }
          return (
            <Fragment key={index}>
              <ItemCard
                id={index}
                url={`/product?id=${id}`}
                imageClassName={classes.cardMedia}
                imageSrc={localedProduct.image}
                imageAlt={'a'}
                titleText={localedProduct.title}
                summaryText={localedProduct.summary}
                showBtn={true}
                buttonText={t('orderNow', { ns: 'common' }).toUpperCase()}
              />
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};
