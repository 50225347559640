import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  pageGrid: {
    overflowX: 'hidden',
    backgroundColor: '#F6F7F7',
    marginTop: '60px',
    paddingBottom: '60px',
    minHeight: '100vh',
  },
  pageHeader: {
    textAlign: 'center',
    position: 'relative',
    height: '50vh',
    backgroundColor: '#8fc17d',
  },
  headerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 1s',
  },
  pageBody: {
    position: 'relative',
    marginBottom: '20px',
    textAlign: 'justify',
    padding: '50px 0',
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '80px',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'start',
    borderBottom: '3px solid #5cdb95',
    width: 'fit-content',
    alignSelf: 'center',
    paddingBottom: '5px',
    marginBottom: '25px',
  },
  subtitle: {
    textAlign: 'center',
    fontWeight: '400',
  },
  sectionGrid: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('mobile')]: {
      flexDirection: 'column-reverse',
      padding: '15px 16px',
      gap: '25px',
    },
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'column-reverse',
      padding: '15px 30px',
      gap: '25px',
    },
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      gap: '50px',
      padding: '15px 150px',
    },
  },
  formGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    backgroundColor: theme.palette.common.white,
    padding: '45px 30px',
    borderRadius: '10px',
    boxShadow: '0 15px 30px -10px rgb(91 97 97 / 30%)',
    [theme.breakpoints.up('mobile')]: {
      position: 'relative',
      width: '100%',
      marginTop: '-50px',
      marginBottom: '0',
    },
    [theme.breakpoints.up('tablet')]: {
      position: 'relative',
      width: '100%',
      marginTop: '-50px',
      marginBottom: '0',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
      margin: '-50px 0',
    },
  },
  formTitle: {
    textAlign: 'start',
    marginBottom: '25px',
  },
  sendbtn: {
    height: '56px',
    width: '100%',
  },
  contactInfoGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    borderRadius: '22px',
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
      alignItems: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
      alignItems: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
      alignItems: 'start',
    },
  },
  contactBtn: {
    width: '280px',
    flexDirection: 'column',
    gap: '12px',
    borderRadius: '22px',
    padding: '10px 25px',
    alignItems: 'start',
    marginBottom: '15px',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      '& $contactTitleIcon': {
        color: theme.palette.primary.main,
      },
      '& $contactTitleText': {
        color: theme.palette.primary.main,
      },
      '& $contactText': {
        color: theme.palette.primary.main,
      },
    },
  },
  contactTitleGrid: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    transition: 'color 0.2s',
  },
  contactTitleIcon: {
    height: '28px',
    width: '28px',
    color: theme.palette.common.white,
    transition: 'color 0.2s',
  },
  contactTitleText: {
    color: theme.palette.common.white,
    transition: 'color 0.2s',
  },
  contactText: {
    color: theme.palette.common.white,
    transition: 'color 0.2s',
  },
}));
