import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  notLoaded: {
    opacity: '0',
  },
  loaded: {
    opacity: '1',
  },
}));
