import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'inherit',
    padding: '40px 0',
    textAlign: 'center',
  },
  card: {
    display: 'flex !important',
    flexDirection: 'column',
    borderRadius: '10px',
    boxShadow: '0 15px 30px -10px rgb(91 97 97 / 30%)',
    padding: '30px',
    height: '420px',
  },
  cardLast: {
    marginRight: 0,
  },
  cardLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  cardContent: {
    height: 'calc(55% - 50px)',
    padding: 0,
    position: 'relative',
    flexDirection: 'column',
    flex: '1',
  },
  cardContentGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    overflow: 'hidden',
    textAlign: 'start',
    gap: '12px',
  },
  cardIcon: {
    width: '35px',
    height: '35px',
  },
  cardTitle: {
    paddingTop: 10,
    marginBottom: '15px',
  },
  cardBody: {
    display: '-webkit-box',
    '-webkitLineClamp': 10,
    '-webkitBoxOrient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#757575',
    fontStyle: 'italic',
  },
  cardActions: {
    justifyContent: 'start',
    padding: '16px 0 0',
    height: '50px',
    maxHeight: '50px',
    flex: 1,
    alignItems: 'center',
    boxSizing: 'content-box',
    flexDirection: 'row',
    gap: '12px',
  },
  cardAvatar: {
    width: '35px',
    height: '35px',
  },
  cardName: {
    fontWeight: 'bold',
  },
}));
