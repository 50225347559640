import { Grid } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import NaturalIcon from '../../../../assets/100_natural.svg';
import GlutenFreeIcon from '../../../../assets/gluten_free.svg';
import NonGMOIcon from '../../../../assets/non_gmo.svg';
import NSFIcon from '../../../../assets/nsf.svg';
import KosherIcon from '../../../../assets/kosher.svg';
import OrganicIcon from '../../../../assets/organic.svg';
import { useTranslation } from 'react-i18next';
import { AnimatedCard } from './components/animatedCard/animatedCard';

interface ICertificatesSection {}

export const CertificatesSection: React.FC<ICertificatesSection> = () => {
  const classes = useStyles();
  const { t } = useTranslation(['pages', 'common']);

  const data = [
    {
      icon: NaturalIcon,
      title: t('home.certTitle1', { ns: 'pages' }),
    },
    {
      icon: GlutenFreeIcon,
      title: t('home.certTitle2', { ns: 'pages' }),
    },
    {
      icon: KosherIcon,
      title: t('home.certTitle5', { ns: 'pages' }),
    },
    {
      icon: NSFIcon,
      title: t('home.certTitle4', { ns: 'pages' }),
    },
    {
      icon: NonGMOIcon,
      title: t('home.certTitle3', { ns: 'pages' }),
    },

    {
      icon: OrganicIcon,
      title: t('home.certTitle6', { ns: 'pages' }),
    },
  ];

  return (
    <Grid className={classes.mainGrid}>
      {data.map((item, index) => {
        return (
          <AnimatedCard
            key={index}
            index={index}
            icon={item.icon}
            title={item.title}
          />
        );
      })}
    </Grid>
  );
};
