import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainGrid: {
    position: 'relative',
    height: 'calc(100vh - 60px)',
    width: '100vw',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    [theme.breakpoints.up('mobile')]: {},
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  bgImg: {
    [theme.breakpoints.up('mobile')]: {
      height: '100%',
      width: '100vw',
      objectFit: 'cover',
      position: 'absolute',
      transition: 'opacity 0.5s',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '100%',
      width: '100vw',
      objectFit: 'cover',
      position: 'absolute',
      transition: 'opacity 0.5s',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '100%',
      width: '50vw',
      objectFit: 'cover',
      position: 'relative',
      transition: 'opacity 0.5s',
      display: 'flex',
      order: '2',
      objectPosition: 'left',
    },
  },
  textGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.up('mobile')]: {
      position: 'absolute',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      padding: '8px 16px',
      bottom: '0',
    },
    [theme.breakpoints.up('tablet')]: {
      position: 'absolute',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      padding: '15px 30px',
      bottom: '0',
    },
    [theme.breakpoints.up('desktop')]: {
      position: 'relative',
      alignItems: 'start',
      textAlign: 'start',
      width: '50%',
      padding: '0 150px',
      height: '100%',
      order: '1',
    },
  },
  title: {
    fontWeight: '900',
    marginBottom: '35px',
  },
  divider: {
    display: 'flex',
    width: '35%',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    marginBottom: '35px',
    [theme.breakpoints.up('mobile')]: {
      alignSelf: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      alignSelf: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      alignSelf: 'start',
    },
  },
  subtitle: {
    margin: '0',
    marginBottom: '25px',
    [theme.breakpoints.up('mobile')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'block',
    },
  },
  shopBtn: {
    width: '50%',
  },
}));
