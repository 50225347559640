import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  cardSlider: {
    '& .slick-slider': {
      position: 'relative',
      display: 'block',
      boxSizing: 'border-box',
      // WebkitUserSelect: 'none',
      // MozUserSelect: 'none',
      // MsUserSelect: 'none',
      // userSelect: 'none',
      // WebkitTouchCallout: 'none',
      // KhtmlUserSelect: 'none',
      MsTouchAction: 'pan-y',
      touchAction: 'pan-y',
      WebkitTapHighlightColor: 'transparent',
    },
    '& .slick list': {
      position: 'relative',
      display: 'block',
      overflow: 'hidden',
      margin: '0',
      padding: '0',
    },
    '& .slick list:focus': {
      outline: 'none',
    },
    '& .slick list.dragging': {
      cursor: 'hand',
    },
    '& .slick-slider .slick-track, .slick-slider .slick list': {
      WebkitTransform: 'translate3d(0, 0, 0)',
      MozTransform: 'translate3d(0, 0, 0)',
      MsTransform: 'translate3d(0, 0, 0)',
      OTransform: 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
    },
    '& .slick-track': {
      position: 'relative',
      top: '0',
      left: '0',
      display: 'block',
    },
    '& .slick-track:before, .slick-track:after': {
      display: 'table',
      content: "''",
    },
    '& .slick-track:after': {
      clear: 'both',
    },
    '& .slick-loading .slick-track': {
      visibility: 'hidden',
    },

    '& .slick-slide': {
      display: 'none',
      float: 'left',
      height: '100%',
      minHeight: '1px',
      padding: '0 15px',
      transition: 'opacity 0.5s',
    },
    '& .slick-slide[aria-hidden="false"]': {
      opacity: '1',
    },
    '& .slick-slide[aria-hidden="true"]': {
      opacity: '0.5',
    },
    "& [dir='rtl'] .slick-slide": {
      float: 'right',
    },
    '& .slick-slide img': {
      display: 'block',
    },
    '& .slick-slide.slick-loading img': {
      display: 'none',
    },
    '& .slick-slide.dragging img': {
      pointerEvents: 'none',
    },
    '& .slick-initialized .slick-slide': {
      display: 'block',
    },
    '& .slick-loading .slick-slide': {
      visibility: 'hidden',
    },
    '& .slick-vertical .slick-slide': {
      display: 'block',
      height: 'auto',
      border: '1px solid transparent',
    },
    '& .slick-arrow.slick-hidden': {
      display: 'none',
    },
    '& .slick-loading .slick list': {
      background: "#fff url('./ajax-loader.gif') center center no-repeat",
    },
    '& .slick-prev, .slick-next': {
      fontSize: '0',
      lineHeight: '0',
      position: 'absolute',
      top: '50%',
      display: 'block',
      width: ' 20px',
      height: ' 20px',
      padding: '0',
      webkitTransform: 'translate(0, -50%)',
      msTransform: 'translate(0, -50%)',
      transform: 'translate(0, -50%)',
      cursor: 'pointer',
      color: 'transparent',
      border: ' none',
      outline: 'none',
      background: 'transparent',
    },
    '& .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus':
      {
        color: 'transparent',
        outline: 'none',
        background: 'transparent',
      },
    '& .slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before':
      {
        opacity: '1',
      },
    '& .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before': {
      opacity: '.25',
    },

    '& .slick-prev:before, .slick-next:before': {
      fontFamily: "'slick;",
      fontSize: '20px',
      lineHeight: ' 1',
      opacity: '0.75',
      color: 'white;',
      webkitFontSmoothing: 'antialiased',
      mozOsxFontSmoothing: 'grayscale',
    },
    '& .slick-prev': {
      left: '-25px',
    },
    "& [dir='rtl'] .slick-prev": {
      right: '-25px',
      left: 'auto',
    },
    '& .slick-prev:before': {
      content: "'←'",
    },
    "& [dir='rtl'] .slick-prev:before": {
      content: '→',
    },
    '& .slick-next': {
      right: '-25px',
    },
    "& [dir='rtl'] .slick-next": {
      right: 'auto',
      left: '-25px',
    },
    '& .slick-next:before': {
      content: "'→'",
    },
    "& [dir='rtl'] .slick-next:before": {
      content: "'←'",
    },
    '& .slick-dotted.slick-slider': {
      marginBottom: '30px',
    },
    '& .slick-dots': {
      position: 'absolute',
      bottom: '-25px',
      display: 'block',
      width: '100%',
      padding: '0',
      margin: '0',
      listStyle: 'none',
      textAlign: 'center',
    },
    '& .slick-dots li': {
      position: 'relative',
      display: 'inline-block',
      width: '20px',
      height: '20px',
      margin: '0 5px',
      padding: '0',
      cursor: 'pointer',
    },
    '& .slick-dots li button': {
      fontSize: '0',
      lineHeight: '0',
      display: 'block',
      width: '20px',
      height: '20px',
      padding: '5px',
      cursor: 'pointer',
      color: 'transparent',
      border: '0',
      outline: 'none',
      background: 'transparent',
    },
    '& .slick-dots li button:hover, .slick-dots li button:focus': {
      outline: 'none',
    },
    '& .slick-dots li button:hover:before, ..slick-dots li button:focus:before':
      {
        opacity: '1',
      },
    '& .slick-dots li button:before': {
      fontFamily: "'slick'",
      fontSize: '6px',
      lineHeight: '20px',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '20px',
      height: '20px',
      content: "'•'",
      textAlign: 'center',
      opacity: '.25',
      color: 'black',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    '& .slick-dots li_slick-active button:before': {
      opacity: '.75',
      color: 'black',
    },
  },
  headerGrid: {
    display: 'flex',
    marginBottom: '50px',
    [theme.breakpoints.up('mobile')]: {
      padding: '0 30px 50px ',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '0 60px 50px ',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '0 150px 50px ',
      justifyContent: 'space-between',
    },
  },
  invisGrid: {
    flex: 1,
    [theme.breakpoints.up('mobile')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
    },
  },
  title: {
    borderBottom: '3px solid #5cdb95',
    width: 'fit-content',
    alignSelf: 'center',
    paddingBottom: '5px',
  },
  arrowsGrid: {
    flex: 1,
    justifyContent: 'end',
    gap: '15px',
    [theme.breakpoints.up('mobile')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
    },
    '&[dir="rtl"]': {
      backgroundColor: 'blue',
    },
  },
  arrowBack: {
    width: '45px',
    height: '45px',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.grey.main,
    '&:focus': {
      backgroundColor: theme.palette.common.grey.main,
    },
  },
  arrowNext: {
    width: '45px',
    height: '45px',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.grey.main,
    '&:focus': {
      backgroundColor: theme.palette.common.grey.main,
    },
  },
}));
