import { Grid, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { CustomImage } from '../../components';
import useStyles from './styles';
import AboutBg from '../../assets/about_bg.webp';
import { useTranslation } from 'react-i18next';
import { Done as DoneIcon } from '@mui/icons-material';
import parse from 'html-react-parser';
import { SEO } from '../../components/seo/seo';
import React from 'react';

interface IAboutPage {}

export const AboutPage: React.FC<IAboutPage> = () => {
  const classes = useStyles();
  const { t } = useTranslation(['pages', 'common']);

  return (
    <>
      <SEO
        title={t('seoAboutTitle', { ns: 'common' })}
        description={t('seoAboutDescription', { ns: 'common' })}
      />
      <Grid className={classes.pageGrid}>
        <Grid className={classes.pageHeader}>
          <CustomImage className={classes.headerImage} src={AboutBg} alt="a" />
        </Grid>
        <Grid className={classes.pageBody}>
          <Grid className={classes.titleGrid}>
            <Typography className={classes.title} variant="h4">
              {t('about', { ns: 'common' }).toUpperCase()}
            </Typography>
          </Grid>{' '}
          <Typography className={classes.parSubtitle} variant="h6">
            {t('about.subtitle1', { ns: 'pages' })}
          </Typography>
          <Typography className={classes.parBody} variant="body1">
            {t('about.par1', { ns: 'pages' })}
            <br />
            <br />
            {t('about.par2', { ns: 'pages' })}
            <br />
            <br />
            {t('about.par3', { ns: 'pages' })}
            <br />
            <br />
            {t('about.par4', { ns: 'pages' })}
            <br />
            <br />
            {t('about.par5', { ns: 'pages' })}
            <br />
            <br />
            {t('about.par6', { ns: 'pages' })}
            <br />
            <br />
            {t('about.par7', { ns: 'pages' })}
          </Typography>
          <Typography className={classes.parSubtitle} variant="h6">
            {t('about.subtitle2', { ns: 'pages' })}
          </Typography>
          <List className={classes.bulletList}>
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <ListItem className={classes.bulletListItem} key={index}>
                <ListItemIcon className={classes.bulletListItemIcon}>
                  <DoneIcon />
                </ListItemIcon>
                <Typography
                  className={classes.bulletListItemBody}
                  variant="body1"
                >
                  {parse(t(`about.bullet${index}`, { ns: 'pages' }))}
                </Typography>
              </ListItem>
            ))}
          </List>
          <Typography className={classes.parBody} variant="body1">
            {t('about.par7', { ns: 'pages' })}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
