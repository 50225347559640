import { alpha, createTheme, darken, lighten } from '@mui/material';

const jpGreen = '#5cdb95';
const jpRed = '#FD151B';
const jpOrange = '#EE6352';
const jpPurple = '#C589E8';

const primary = jpGreen;
const primaryLight = lighten(jpGreen, 0.8);
const primaryDark = '#79a867';

const secondary = '#FFEEDB';
const secondaryLight = lighten(secondary, 0.7);
const secondaryDark = darken(secondary, 0.25);

const warning = '#FFC260';
const success = '#3CD4A0';
const info = '#9013FE';

const remFontUnit = 0.0625;

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      desktop: 1024,
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Rubik'].join(','),
    h1: {
      fontSize: `${remFontUnit * 96}rem`,
      color: 'black',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: `${remFontUnit * 60}rem`,
      color: 'black',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: `${remFontUnit * 48}rem`,
      color: 'black',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: `${remFontUnit * 34}rem`,
      color: 'black',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: `${remFontUnit * 24}rem`,
      color: 'black',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: `${remFontUnit * 20}rem`,
      color: 'black',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: `${remFontUnit * 16}rem`,
      color: 'black',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: `${remFontUnit * 14}rem`,
      color: 'black',
    },
    body1: {
      fontSize: `${remFontUnit * 16}rem`,
      color: 'black',
    },
    body2: {
      fontSize: `${remFontUnit * 14}rem`,
      color: 'black',
    },
    button: {
      fontSize: `${remFontUnit * 14}rem`,
      color: 'black',
    },
    caption: {
      fontSize: `${remFontUnit * 12}rem`,
      color: 'black',
    },
    overline: {
      fontSize: `${remFontUnit * 10}rem`,
      color: 'black',
    },
  },
  spacing: 2,
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
      dark: primaryDark,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: secondary,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: '#000000',
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    info: {
      main: info,
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
    },
    background: {
      default: '#F6F7FF',
      light: '#F3F5FF',
    },
    common: {
      grey: { main: lighten('#000000', 0.9) },
      darkGrey: { main: lighten('#000000', 0.4) },
      green: {
        main: jpGreen,
        light: lighten(jpGreen, 0.5),
      },
      red: {
        main: jpRed,
        light: lighten(jpRed, 0.9),
      },
      orange: {
        main: jpOrange,
        light: lighten(jpOrange, 0.5),
      },
      purple: {
        main: jpPurple,
        light: lighten(jpPurple, 0.8),
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollPaddingTop: '60px',
          scrollBehavior: 'smooth',
          '-webkit-touch-callout': 'none',
          '-webkit-user-select': 'none',
          '-khtml-user-select': 'none',
          '-moz-user-select': 'none',
          '-ms-user-select': 'none',
          'user-select': 'none',
          minHeight: '-webkit-fill-available',
        },
        body: {
          '& #root': {
            scrollPaddingTop: '60px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: '40px',
          minWidth: '180px',
          width: 'fit-content',
          borderRadius: '22px',
          boxShadow: 'none',
          textTransform: 'none',
          margin: '0',
          padding: '10px 28px',
          fontWeight: '700',
          ...(ownerState.variant === 'contained' && {
            backgroundColor: theme.palette.primary.main,
            ':hover': {
              backgroundColor: lighten(theme.palette.primary.main, 0.2),
              boxShadow: 'none',
            },
            ':focus': {
              backgroundColor: theme.palette.primary.main,
              boxShadow: 'none',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            backgroundColor: 'transparent',
            borderWidth: '3px',
            borderColor: theme.palette.common.black,
            color: theme.palette.common.black,
            ':hover': {
              borderWidth: '3px',
              color: theme.palette.primary.main,
            },
          }),
          ...(ownerState.variant === 'text' && {
            color: theme.palette.common.black,
            backgroundColor: '#f7f8f8',
            ':hover': {
              color: theme.palette.primary.main,

              backgroundColor: darken('#f7f8f8', 0.2),
            },
            '&& .MuiTouchRipple-child': {
              backgroundColor: darken('#f7f8f8', 0.2),
            },
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: '40px',
          borderRadius: '99em',
          boxShadow: 'none !important',
          textTransform: 'none',
          margin: '0 !important',
          backgroundColor: 'transparent',
          color: theme.palette.common.black,
          ...(ownerState.variant === 'contained' && {
            color: theme.palette.common.black,
            backgroundColor: '#DFDFE1',
            ':hover': {
              backgroundColor: alpha('#000000', 0.8),
            },
            ':active': {
              backgroundColor: '#ff0000',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            backgroundColor: 'transparent',
            ':hover': {
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
            },
          }),
          ...(ownerState.variant === 'text' && {
            color: '#DFDFE1',
            ':hover': {
              backgroundColor: alpha(theme.palette.common.black, 0.08),
            },
            '&& .MuiTouchRipple-child': {
              backgroundColor: alpha(theme.palette.common.black, 0.08),
            },
          }),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: alpha(theme.palette.common.black, 0.6),
        }),
        filled: ({ theme }) => ({
          '&.MuiInputLabel-shrink': {},
        }),
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
        style: { textAlign: 'start' },
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '22px',
          '&.Mui-focused': {},
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        // root: ({ ownerState, theme }) => ({
        //   borderRadius: '22px',
        // }),
        filled: ({ theme }) => ({
          '&.MuiSelect-select': {
            borderRadius: '22px',
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0',
        },
        paper: {
          borderRadius: '10px !important',
        },
      },
    },
  },
});

export default defaultTheme;
