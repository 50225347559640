import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as firestore from '../../firebase/firestore';
import { IArticle } from '../models';

const getArticles = createAsyncThunk(
  'article/getArticles',
  async (_, { rejectWithValue }): Promise<Array<IArticle>> => {
    return firestore
      .getAllArticles()
      .then((response) => response)
      .catch<any>((err) => rejectWithValue(err));
  },
);

interface IArticleState {
  articles: IArticle[];
}

const initialState: IArticleState = {
  articles: [],
};

export const articleSlice = createSlice({
  name: 'article',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  Get Articles
    builder.addCase(getArticles.pending, (state) => {});
    builder.addCase(getArticles.fulfilled, (state, { payload }) => {
      state.articles = payload;
    });
    builder.addCase(getArticles.rejected, (state, { error }) => {});
  },
});

//  Actions
export const {} = articleSlice.actions;
//  Async Actions
export { getArticles };
//  Reducer
export default articleSlice.reducer;
