import { Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import HomeBg from '../../../../assets/welcome_bg.webp';
import { Link } from 'react-router-dom';
import { CustomImage } from '../../../../components';
import { useTranslation } from 'react-i18next';

interface IWelcomeSection {}

export const WelcomeSection: React.FC<IWelcomeSection> = () => {
  const classes = useStyles();
  const { t } = useTranslation(['pages', 'common']);

  return (
    <Grid className={classes.mainGrid}>
      <CustomImage className={classes.bgImg} src={HomeBg} alt="Home page" />
      <Grid className={classes.textGrid}>
        <Typography sx={{ my: 4 }} className={classes.title} variant="h4">
          {t('home.welcomeTitle', { ns: 'pages' })}
        </Typography>
        <Divider className={classes.divider}></Divider>
        <Typography sx={{ my: 4 }} className={classes.subtitle} variant="body1">
          {t('home.welcomeSubtitle', { ns: 'pages' })}
        </Typography>
        <Button
          className={classes.shopBtn}
          variant="outlined"
          component={Link}
          sx={{ mx: 2 }}
          to="/products"
        >
          {t('products', { ns: 'common' })}
        </Button>
      </Grid>
    </Grid>
  );
};
