import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  mainGrid: {
    display: 'flex',
    position: 'relative',
    textAlign: 'center',
    width: '100vw',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up('mobile')]: {
      padding: '40px 16px',
      flexDirection: 'column',
      gap: '50px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '40px 30px',
      flexDirection: 'column',
      gap: '50px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '40px 150px',
      flexDirection: 'row',
      gap: '80px',
    },
  },
  mainGridInv: {
    [theme.breakpoints.up('mobile')]: {},
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row-reverse',
    },
  },
  textGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('mobile')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },
  title: {
    marginBottom: '25px',
  },
  divider: {
    display: 'flex',
    width: '35%',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    alignSelf: 'center',
    marginBottom: '25px',
  },
  body: {
    marginBottom: '25px',
  },
  image: {
    objectFit: 'contain',
    [theme.breakpoints.up('mobile')]: {
      height: '350px',
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '350px',
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '500px',
      width: '50%',
    },
  },
  btn: {
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
  },
}));
