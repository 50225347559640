import useStyles from './styles';
import classNames from 'classnames';
import React, { useState } from 'react';

interface ICustomImage {
  className: string;
  src: string;
  alt?: string;
}

export const CustomImage: React.FC<ICustomImage> = ({
  className,
  src,
  alt,
}) => {
  const classes = useStyles();
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <img
      className={classNames(className, {
        [classes.loaded]: imageLoaded,
        [classes.notLoaded]: !imageLoaded,
      })}
      onLoad={() => setImageLoaded(true)}
      src={src}
      alt={alt}
    />
  );
};
