import { Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomImage, ItemCard } from '../../components';
import useStyles from './styles';
import ArticlesBg from '../../assets/articles_bg.webp';
import { SEO } from '../../components/seo/seo';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/redux';
import { getArticles } from '../../../core/state/reducers/articles.reducers';
import { IArticle } from '../../../core/state/models';
import { IAData } from '../../../core/state/models/article';

interface IArticlesPage {}

export const ArticlesPage: React.FC<IArticlesPage> = () => {
  const articles = useAppSelector((state) => state.article.articles);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(['common']);

  const classes = useStyles();

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  return (
    <>
      <SEO
        title={t('seoArticlesTitle', { ns: 'common' })}
        description={t('seoArticlesDescription', { ns: 'common' })}
      />
      <Grid className={classes.pageGrid}>
        <Grid className={classes.pageHeader}>
          <CustomImage
            className={classes.headerImage}
            src={ArticlesBg}
            alt="a"
          />
        </Grid>
        <Grid className={classes.pageBody}>
          <Grid className={classes.titleGrid}>
            <Typography className={classes.title} variant="h4">
              {t('articles', { ns: 'common' }).toUpperCase()}
            </Typography>
          </Grid>
          <Grid className={classes.cardGrid}>
            {articles.map((article, index) => {
              let localedArticle: IAData;
              const id = article.id;
              switch (i18n.language) {
                case 'he': {
                  if (article.data.he === undefined) {
                    localedArticle = article.data.en;
                  } else {
                    localedArticle = article.data.he;
                  }
                  break;
                }
                case 'rus': {
                  if (article.data.rus === undefined) {
                    localedArticle = article.data.en;
                  } else {
                    localedArticle = article.data.rus;
                  }
                  break;
                }
                case 'en':
                default:
                  localedArticle = article.data.en;
              }
              return (
                <Fragment key={index}>
                  <ItemCard
                    id={index}
                    url={`/article?id=${id}`}
                    imageSrc={localedArticle.image}
                    imageAlt={'a'}
                    imageClassName={classes.cardMedia}
                    titleText={localedArticle.title}
                    summaryText={localedArticle.summary}
                    showBtn={true}
                    buttonText={t('readMore', { ns: 'common' }).toUpperCase()}
                  />
                </Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
