import { Menu, MenuItem, PopoverProps } from '@mui/material';
import useStyles from './styles';
import ReactCountryFlag from 'react-country-flag';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

interface IDropMenuOption {
  title: string;
  countryCode: string;
  onClick: MouseEventHandler<HTMLLIElement>;
}

interface IDropMenu {
  anchorEl: PopoverProps['anchorEl'];
  open: boolean;
  onClose: PopoverProps['onClose'];
  options: Array<IDropMenuOption>;
  selectedId: number;
}

export const DropMenu: React.FC<IDropMenu> = ({
  anchorEl,
  open,
  onClose,
  options,
  selectedId,
}) => {
  const classes = useStyles();

  return (
    <Menu
      PaperProps={{ style: { borderRadius: '22px' } }}
      MenuListProps={{ style: { padding: '0' } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      {options.map((option, index) => {
        return (
          <MenuItem
            key={index}
            className={classNames(classes.menuItem, {
              [classes.menuItemSelected]: selectedId === index,
            })}
            onClick={option.onClick}
            disableRipple
          >
            <ReactCountryFlag
              className={classes.menuItemFlag}
              svg
              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
              countryCode={option.countryCode}
            />
            {option.title}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
