import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('mobile')]: {
      padding: '4px 16px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '4px 30px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '4px 150px',
    },
  },
  copyright: {
    fontWeight: 'bold',
  },
}));
